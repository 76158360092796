.postHeader {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 2rem;
  max-width: 1000px;
  margin: auto;
  .feature-img {
    height: 0;
    padding-bottom: 40%;
    width: 100%;
    max-width: 100%;
    margin: auto;
    border: 1px solid #00000011;
    background-color: #eee; }
  &--withToc {
    max-width: 1120px;
    .feature-img {
      width: 1120px;
      max-width: 100%; } }
  .feature-content {
    padding-bottom: 1.5rem; }
  .feature-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem; } }
