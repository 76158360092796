.mediaBlock {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .features-intro {
    text-align: center;
    max-width: 45rem;
    margin: 0 auto;
    font-size: 120%;
    padding-bottom: 3rem; }
  .media-list {
    @extend .article;
    .feature-title {
      margin-top: 0!important;
      font-size: 1.1em; } } }
