$card-width: 360px !default;
$card-gap: 12px !default;
$card-double-size: ($card-width * 2 + $card-gap * 2);

.feature-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
.feature-text {
  position: relative; }
.feature-img {
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: relative; }
.feature-img-license {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  opacity: 0.5;
  font-size: 12px;
  color: white;
  &:hover {
    opacity: 1;
    .feature-img-license-text {
      display: block; } }
  a {
    color: inherit;
    text-decoration: underline; }
  .feature-img-license-c {
    background: rgba(0,0,0,.5); }
  .feature-img-license-text {
    display: none;
    background: rgba(0,0,0,.5); }
  >div {
    line-height: 1em;
    padding: 3px; } }
.feature-content {
  @extend .content;
  &--centered {
    text-align: center; } }
.feature-pre-title {
  font-weight: 500;
  font-size: 90%; }
.feature-cta {
  margin-top: 2rem; }
.feature-categories {
  margin-top: 1em;
  display: inline-block; }

.features {
  background: #edf3f3;
  padding-top: 3rem;
  padding-bottom: 3rem; }
.features-intro {
  text-align: center;
  max-width: 45rem;
  margin: 0 auto;
  font-size: 120%;
  padding-bottom: 3rem; }
.feature-cards {
  @extend .container;
  @extend .is-fluid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -($card-gap) auto;
  padding: 0; }

section {
  &.features, &.splitBanner, &.textBanner {
    border-bottom: 1px solid #00000014; } }

.feature {
  flex: 0 0 $card-width;
  max-width: 100%;
  margin: $card-gap;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
  border: 1px solid #ddd;
  background: #fff;
  .feature-img {
    height: 0;
    width: 100%;
    padding-bottom: 60%;
    border-bottom: 1px solid #0000001a;
    &.feature-post-color-0 {
     background: #f2cc8f33;
     color: #f2cc8f; }
    &.feature-post-color-1 {
     background: #81b29a33;
     color: #81b29a; }
    &.feature-post-color-2 {
     background: #3d405b33;
     color: #3d405b; }
    &.feature-post-color-3 {
     background: #e07a5f33;
     color: #e07a5f; }
    &.feature-post-color-4 {
     background: #555;
     color: #f4f1de; }
    &.feature-post-color-5 {
     background: #333;
     color: #eee; }
    a {
      font-size: 300px;
      margin: 0;
      padding: 0;
      line-height: 1;
      color: inherit;
      font-weight: bold;
      overflow: hidden;
      letter-spacing: -10px;
      margin-left: -20px; } }
  .feature-content {
    padding: 24px 24px 48px 24px; }
  .feature-description {
    color: #5e5e5e; }
  .feature-title {
    margin-top: 0!important; } }

@mixin featureDoubleWidth {
  display: flex;
  flex: 0 0 $card-double-size;
  .feature-img {
    max-height: none;
    height: 100%;
    border-bottom: none;
    border-right: 1px solid #0000001a; }
  >div {
    flex: 0 0 50%; } }

.feature--double-width {
  @include featureDoubleWidth; }


@media screen and (min-width: 1211px) {
  .feature-cards {
    .feature:nth-child(odd):last-of-type:not(:nth-child(3n)) {
      @include featureDoubleWidth; } }
  .feature-cards.has-4-elements {
    .feature:nth-child(1), .feature:nth-child(4) {
      @include featureDoubleWidth; } } }

@media screen and (min-width: 710px + $container-offset) and (max-width: 1210px) {
  .feature-cards {
    .feature:last-of-type:nth-child(3n), .feature:last-of-type:nth-child(5n) {
      @include featureDoubleWidth; } } }

@media screen and (max-width: 710px + $container-offset) {
  .feature--double-width {
    flex-direction: column;
    flex: 0 0 350px;
    >div:first-of-type {
      height: 0;
      width: 100%;
      padding-bottom: 60%; } } }




.splitBanner {
  display: flex;
  max-width: 2000px;
  margin: auto;
  >div {
    flex: 0 0 50%; }
  .feature-content {
    padding: 5rem;
    font-size: 120%; }
  &--reverse {
    flex-direction: row-reverse; }
  .feature-title {
    margin-top: .5em!important; } }

@include until($desktop) {
  .splitBanner {
    .feature-content {
      padding: 2rem; } } }

@include until(600px) {
  .splitBanner {
    flex-direction: column-reverse;
    .feature-content {
      padding: 2rem;
      margin: auto; }
    >div {
      flex: 0 0 100%; }
    .feature-img {
      width: 80%;
      height: 0;
      padding-bottom: 80%;
      margin: 1rem auto;
      border-radius: 50%; }
    .feature-img-license {
      display: none; } } }

.product {
  @extend .splitBanner;
  .feature-content {
    border-left: 1px solid #00000014; }
  .feature-img {
    background-size: contain;
    background-color: #edf3f3;
    background-repeat: no-repeat; }
  &--align-bottom .feature-img {
    background-position: bottom; }
  &--align-left .feature-img {
    background-position: left; }
  &--align-right .feature-img {
    background-position: right; }
  &--align-top .feature-img {
    background-position: top; } }
