.PageNavigation {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
  margin-top: 1rem; }

.PageNavigation a {
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0; }


.PageNavigation .next {
  text-align: right; }
