.mediaFeature {
  display: flex;
  max-width: 2000px;
  margin: auto;
  >div {
    flex: 0 0 50%; }
  &--reverse {
    flex-direction: row-reverse; }
  .feature-title {
    margin-top: .5em; }
  &--reverse {
    flex-direction: row-reverse; }
  .feature-content {
    padding: 5rem 2rem;
    max-width: calc(400px + 4rem);
    font-size: 120%; }
  .mediaFeature-image {
    text-align: right;
    padding: 5rem 2rem;
    img {
      max-width: 100%;
      width: 400px;
      max-height: 90vh;
      box-shadow: 0px 0px 10px 5px #00000011; } }
  &--align-left .feature-img {
    text-align: left; }
  &--align-right .feature-img {
    text-align: right; } }

@include until(600px) {
  .mediaFeature {
    flex-direction: column;
    .mediaFeature-image {
      padding: 2rem;
      padding-bottom: 0;
      position: relative; }
    .feature-content {
      padding: 2rem;
      margin: auto; }
    .feature-img {
      text-align: center!important; } } }


