$navbar-breakpoint: 600px;
@import "theme/_main";

.home {
  .textBanner {
    border-bottom: none;
    .feature-content {
      padding: 2rem 0 0 0;
    }
    .feature-title {
      // color: #96982e;
      margin: 2em 0 0 0;
    }
  }
}

.bg-light {
  background: #edf3f3
}

.bg-dark {
  background: #e5ebeb
}

.splitBanner {
  max-width: 1600px;
}

.bog__jord {
  .postHeader .feature-img {
    background-position-y: 35%;
  }
}

.bog__alvilda {
  .postHeader .feature-img {
    background-position-y: 90%;
  }
}

.bog__lever {
  .postHeader .feature-img {
    background-position-y: 50%;
  }
}

.small {
  font-size: 85%;
}

.page404 {
  .area_404 {
    text-align: center;
    margin: 50px auto;
    margin-bottom: 100px;
    h1 {
      font-size: 140px;
      font-weight: 900;
      line-height: 1;
    }
    svg {
      max-width: 80%;
      width: 200px;
      .book {
        fill: #4a4a4a;
      }
    }
    p {
      font-size: 28px;
      font-weight: 900;
    }
  }
}

.image-lg, .image-md, .image-sm {
  margin: auto;
  max-width: 100%;
  display: block;
  margin-bottom: 24px;
}

.image-md {
  width: 500px;
}

.image-sm {
  width: 300px;
}

.blogpost {
  .feature-content {
    padding-bottom: 0;
    h3 {
      margin: 0;
    }
  }
  +.container > .article {
    margin-top: 1.5rem;
  }
}