.boxHero {
  @extend .floatingHero;
  justify-content: flex-end;
  .feature-content {
    background: white;
    width: 45rem;
    margin: 0 auto;
    padding: 1.5rem; }
  .feature-img:after {
    content: none; }
  .feature-title {
    font-size: 2rem;
    font-weight: bold; }
  .feature-description {
    font-size: 100%;
    margin-bottom: 0; }
  .feature-cta {
    margin-top: 1rem; } }
