// Variable overrides
// Need to be loaded first, see https://bulma.io/documentation/customize/variables/
@import "_variables";            // Overrides for Bulma variables
// @import "_darkVariables";       // Dark theme overrides for Bulma variables

// Bulma
@import "../bulma/bulma.sass";  // Bulma scss

// Theme scss
//
// Ordered according to the order of the html elements
// @import "_darkOverrides";     // Dark theme
@import "_navbar";            // navbar style
@import "_floatingHero";      // hero header blocks
@import "_boxHero";           // hero header blocks
@import "_postHeader";        // hero header blocks
@import "_textBanner";        // hero header blocks
@import "_features";          // features
@import "_media";             // features
@import "_documents";         // document list
@import "_language";          // styling for language selector and splash page
@import "_prevNext";          // styling for language selector and splash page
@import "_misc";              // various other styles
@import "_feedback";          // feedback from the theme - not included in production

:root {
  --navbar-height: #{$navbar-height};
}