// Set customizable colors
//
// Set default colors
$default_link_color: #007bff;
$default_banner_color: #007bff;
$default_footer_color: #6c757d;

// Get custom colors defined in _config.yml
// Custom colors are optional, meaning they can be undefined in _config.yml
// Since an empty "$custom_links: ;" would result in a scss compilation error,
// we combine them with a default color as a space-separated list
$custom_link_color: $default_link_color ;
$custom_banner_color: $default_banner_color ;
$custom_footer_color: $default_footer_color ;

// We then select the last element of the list:
// custom color if defined, default color if undefined
$custom_link_color: nth($custom_link_color, -1);
$custom_banner_color: nth($custom_banner_color, -1);
$custom_footer_color: nth($custom_footer_color, -1);

// Set to default color if custom color is an invalid color value
@if type-of($custom_link_color) != color { $custom_link_color: $default_link_color; }
@if type-of($custom_banner_color) != color { $custom_banner_color: $default_banner_color; }
@if type-of($custom_footer_color) != color { $custom_footer_color: $default_footer_color; }

// Set enable-rounded
$enable-rounded: true;
$custom_rounded: "";
@if $custom_rounded == "false" {
  $enable-rounded: false;
}


h1, h2, h3, h4, h5, h6 {
  a {
    color: inherit;
  }
}



  $primary: #4787fb;



  $link: #4787fb;



  $isSquare: true;
  $radius-small: 0;
  $radius: 0;
  $radius-large: 0; 



$navbar-brand-background-color: false !default;


$hasThemeFeedback: false !default;

@import "_main";
